import { Action } from 'redux-actions';
import { ActionsObservable, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { TimeIntervalsActions } from './timeIntervals.actions';
import { getTimeIntervals, IGetTimeIntervals } from './timeIntervals.service';

export const getTimeIntervals$ = (actions$: ActionsObservable<Action<IGetTimeIntervals>>) => {
  return actions$.pipe(
    ofType(TimeIntervalsActions.GetTimeIntervals),
    debounceTime(1000),
    switchMap(({ payload }) =>
      getTimeIntervals(payload).pipe(
        map((payload) => ({
          type: TimeIntervalsActions.GetTimeIntervalsSuccess,
          payload,
        })),
        catchError((err) =>
          of({
            type: TimeIntervalsActions.GetTimeIntervalsError,
            payload: err,
          }),
        ),
      ),
    ),
  );
};
