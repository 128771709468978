import React from 'react';
import { Button, CircularProgress, Input } from '@material-ui/core';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../state';
import { Map } from '../../../../sprites';
import AutoCompleteAddress from '../../../AutoCompleteAddress';
import DateComponent from './components/DateComponent';
import Precise from './components/Precise';
import TimeIntervals from './components/TimeIntervals';
import styles from './Form.module.scss';

const Form = ({
  handleSubmit,
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  isValid,
  setMap,
  setCoardinats,
  errorLine,
  serAddresError,
  addresError,
}: any) => {
  const {
    delivery: { data: delivery, isLoading },
    timeIntervals: { data: timeIntervalsData, isLoading: timeIntervalsLoading },
  } = useSelector((state: Store) => state);

  const submitButtonRef = React.useRef<HTMLButtonElement>(null);

  const customerSchedulingKind = delivery?.attributes?.delivery?.partner?.customer_scheduling_kind;

  const validAddres = React.useMemo(() => !!errors.addres || !values.addres, [errors.addres, values.addres]);

  const deliveryTime = React.useMemo(() => {
    const precise = <Precise {...{ setFieldValue, values, visible: validAddres, disabled: !!addresError }} />;

    const timeIntervals = (
      <TimeIntervals {...{ setFieldValue, values, visible: validAddres, disabled: !!addresError }} />
    );

    if (['both'].includes(customerSchedulingKind!) && !timeIntervalsLoading && timeIntervalsData.length !== 0)
      return (
        <div className={cn(styles.Block, styles.Flex)}>
          {precise}
          {timeIntervals}
        </div>
      );

    return (
      <>
        <div className={cn(styles.Block)}>{precise}</div>
        <div className={cn(styles.Block)}>{timeIntervals}</div>
      </>
    );
  }, [
    addresError,
    customerSchedulingKind,
    setFieldValue,
    timeIntervalsData.length,
    timeIntervalsLoading,
    validAddres,
    values,
  ]);

  return (
    <div className={cn(styles.Form)}>
      <h1 className={styles.Title}>Доставка от {delivery?.attributes.delivery.partner.name} уже близко!</h1>
      <p className={styles.Description}>
        {`${delivery?.attributes.delivery.destination.first_name}, мы готовы доставить вам ${
          delivery?.attributes.delivery.product.short_name || delivery?.attributes.delivery.product.name
        }.
        Выберите удобное место и дату для встречи`}
      </p>
      <form onSubmit={handleSubmit} className={styles.Form}>
        <p className={styles.SubTitle}>Адрес</p>
        <div className={styles.Block}>
          <AutoCompleteAddress
            value={values.addres}
            setCoardinats={setCoardinats}
            onChange={(v) => setFieldValue('addres', v, false)}
            onBlur={handleBlur}
            onError={serAddresError}
          />
          {errorLine}
        </div>
        <div className={styles.Block}>
          <Input
            placeholder="Комментарий (подъезд, этаж, домофон и всё, что поможет вас легче найти)"
            className={cn(styles.Input, styles.Notes)}
            value={values.notes}
            onChange={handleChange}
            onBlur={handleBlur}
            id="notes"
            name="notes"
            type="text"
          />
          {touched.notes && errors.notes ? <div className={styles.Error}>{errors.notes}</div> : null}
        </div>
        <div className={cn(styles.Block, styles.Map)}>
          <Button onClick={() => setMap(true)}>
            <Map size={50} />
            Показать зону доставки
          </Button>
        </div>
        <div className={cn(styles.Block, styles.DatePickers)}>
          <DateComponent
            {...{
              setFieldValue: (...args: any[]) => {
                submitButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
                setFieldValue?.(...args);
              },
              values,
              errors,
              disabled: !!addresError,
            }}
          />
        </div>

        {deliveryTime}

        <div className={cn(styles.Block)}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            className={styles.Subbmit}
            disabled={!isValid || isLoading || !!addresError}
            ref={submitButtonRef}
          >
            Готово
            {isLoading && <CircularProgress size={24} className={cn(styles.CircularProgress)} />}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Form;
