import { Action } from 'redux-actions';
import { ActionsObservable, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { ZonesActions } from './zones.actions';
import { getZones, IGetZones } from './zones.service';

export const getZones$ = (actions$: ActionsObservable<Action<IGetZones>>) => {
  return actions$.pipe(
    ofType(ZonesActions.GetZones),
    debounceTime(1000),
    switchMap(({ payload }) =>
      getZones(payload).pipe(
        map((payload) => ({
          type: ZonesActions.GetZonesSuccess,
          payload,
        })),
        catchError((err) =>
          of({
            type: ZonesActions.GetZonesError,
            payload: err,
          }),
        ),
      ),
    ),
  );
};
