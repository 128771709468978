import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import React, { memo, useMemo } from 'react';
import styles from './CustomDatePicker.module.scss';

type ICustomDatePickerProps = {
  setFieldValue: any;
  month: Moment;
  disabled: boolean;
  className?: string;
  current: boolean;
  nextDeliveryDate?: string;
  holidays?: string[];
} & Omit<DatePickerProps, 'onChange'>;

const CustomDatePicker = ({
  value,
  setFieldValue,
  month,
  disabled,
  className,
  current,
  nextDeliveryDate,
  holidays,
  ...rest
}: ICustomDatePickerProps) => {
  const minDate = useMemo(() => {
    return current ? month.add(1, 'd').format('YYYY-MM-DD') : month.startOf('month').format('YYYY-MM-DD');
  }, [current, month]);

  return (
    <div className={styles.CustomDatePicker}>
      <DatePicker
        {...rest}
        disabled
        disableToolbar
        className={className}
        variant="static"
        minDate={minDate}
        maxDate={month.endOf('month').format('YYYY-MM-DD')}
        openTo="date"
        value={
          moment(value).isBetween(
            month.startOf('month').format('YYYY-MM-DD'),
            month.add(1, 'd').endOf('month').format('YYYY-MM-DD'),
            null,
            '()',
          )
            ? moment(value)
            : null
        }
        onChange={(date) => setFieldValue('date', date?.format('YYYY-MM-DD'), false)}
        initialFocusedDate={month}
        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
          let className = '';

          //     if (holidays?.includes(moment(date).format('YYYY-MM-DD'))) return true;
          // return moment(date) < moment(nextDeliveryDate);

          if (
            dayInCurrentMonth &&
            (disabled ||
              moment().valueOf() >= (day as any)?.valueOf() ||
              dayComponent.props.disabled ||
              moment(day) < moment(nextDeliveryDate) ||
              holidays?.includes(moment(day).format('YYYY-MM-DD')))
          ) {
            return (
              // stopPropagation не даем браузеру обрабатывать вложенные клики
              <div onClick={(e) => e.stopPropagation()}>
                {React.cloneElement(dayComponent, {
                  className: 'MuiButtonBase-root MuiIconButton-root MuiPickersDay-day MuiPickersDay-dayDisabled',
                })}
              </div>
            );
          }

          dayInCurrentMonth &&
            dayComponent.props.current &&
            !dayComponent.props.selected &&
            (className = `${styles.CurrentDay} MuiPickersDay-day`);

          dayInCurrentMonth &&
            value &&
            moment(value).valueOf() !== day?.valueOf() &&
            dayComponent.props.selected &&
            (className = `${className} MuiPickersDay-day`);

          if (className) {
            return React.cloneElement(dayComponent, { className });
          }

          // если день не выбран, но календарь пытается задать выбранный день то мы ему не даем это сделать
          if (!value && dayComponent.props.selected) return React.cloneElement(dayComponent, { className });

          return dayComponent;
        }}
      />
    </div>
  );
};

export default memo(CustomDatePicker);
