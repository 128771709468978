import React from 'react';
import { useInterval } from 'react-use';
import { useState } from 'react';
import cn from 'classnames';

import styles from './LoadingPage.module.scss';

const LoadingPage = () => {
  const [ellipsis, setEllipsis] = useState(3);

  useInterval(() => {
    setEllipsis((prev) => {
      return ellipsis >= 3 ? 0 : prev + 1;
    });
  }, 500);

  return (
    <div className={cn(styles.LoadingPage)}>
      <div className={cn(styles.Body)}>
        <div className={cn(styles.Description)}>
          Загрузка
          <div className={cn({ [styles.Hidden]: ellipsis < 1 })}>.</div>
          <div className={cn({ [styles.Hidden]: ellipsis < 2 })}>.</div>
          <div className={cn({ [styles.Hidden]: ellipsis < 3 })}>.</div>
        </div>

        <div className={styles.WalkContainer}>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
