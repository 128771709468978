import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { YMInitializer } from 'react-yandex-metrika';
import { ruRU } from '@material-ui/core/locale';
import MomentUtils from '@date-io/moment';
import { YMaps } from 'react-yandex-maps';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import 'moment/locale/ru';

import { NotificationsActions } from './state/notifications/notifications.actions';
import NotFound from './shared/components/NotFound';
import Delivery from './scens/Delivery';
import { Store } from './state';

import './App.scss';

// Подключаем google аналитику
ReactGA.initialize('G-SW2T91N93J');
ReactGA.pageview(window.location.pathname + window.location.search);

moment.locale('ru');

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#333333',
        light: '#5c5c5c',
        dark: '#0c0c0c',
      },
      secondary: {
        main: '#B2B2B2',
        light: '#e4e4e4',
        dark: '#838383',
      },
    },
  },
  ruRU,
);

const Snack: FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { notifications } = useSelector((state: Store) => state);

  useEffect(() => {
    const { variant, message } = notifications;

    if (variant) {
      enqueueSnackbar(message, { variant });
      dispatch({ type: NotificationsActions.SetNotifications });
    }
  }, [notifications, enqueueSnackbar, dispatch]);

  return <></>;
};

function App() {
  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <Snack />
        <YMaps query={{ lang: 'ru_RU', load: 'util.bounds' }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={theme}>
              <Router>
                <Switch>
                  <Route path="/:uuid">
                    <Delivery />
                  </Route>
                  <Route component={NotFound} />
                </Switch>
              </Router>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </YMaps>
      </SnackbarProvider>

      {/* Подключаем Yandex Metrika */}
      <YMInitializer accounts={[71232376]} options={{ webvisor: true }} />
    </>
  );
}

export default App;
