import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { CityAreasActions } from '../../state/cityAreas/cityAreas.actions';
import { DeliveryActions } from '../../state/delivery/delivery.actions';
import LoadingPage from '../../shared/components/LoadingPage';
import Completed from '../../shared/components/Completed';
import NotFound from '../../shared/components/NotFound';
import Ongoing from '../../shared/components/Ongoing';
import { Store } from '../../state';

import styles from './Delivery.module.scss';

const Delivery = () => {
  const { uuid }: { uuid: string } = useParams();
  const dispatch = useDispatch();

  const { delivery } = useSelector((state: Store) => state);

  const status = useMemo(() => delivery.data?.attributes.status, [delivery.data]);

  const view = useMemo(() => {
    if (status === 'ongoing') return <Ongoing />;
    if (status === 'completed') return <Completed />;
    if (![undefined, true].includes(delivery.isLoading)) return <NotFound />;
    if (!delivery.data && delivery.isLoading) return <LoadingPage />;

    return null;
  }, [status, delivery]);

  useEffect(() => {
    dispatch({
      type: DeliveryActions.GetDelivery,
      payload: { uuid },
    });
    dispatch({
      type: CityAreasActions.GetCityAreas,
      payload: { uuid },
    });
  }, [dispatch, uuid]);

  return <div className={cn(styles.Delivery)}>{view}</div>;
};

export default Delivery;
