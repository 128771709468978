import qs from 'qs';
import Axios from 'axios-observable';
import { map } from 'rxjs/operators';

import { headers } from '../../shared/const/store';

Axios.defaults.paramsSerializer = (p) => {
  return qs.stringify(p, { encode: false, arrayFormat: 'brackets' });
};

export interface IGetZones {
  params: {
    lat: number;
    lon: number;
  };
}

export const getZones = ({ params }: IGetZones) => {
  return Axios.request({
    method: 'GET',
    headers,
    url: `${process.env.REACT_APP_URL}zones`,
    params,
  }).pipe(map(({ data: { data } }) => ({ data })));
};
