import React, { useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import Logo from '../Logo';
import { Store } from '../../../state';
import PromptPanel from './componetns/PromptPanel';
import OngoingButton from './componetns/OngoingButton';
import InformationBlocks from './componetns/InformationBlocks';
import { useCourierMap } from './hooks';
import { Map } from '../../sprites';

import styles from './Completed.module.scss';
import { CourierMap } from './componetns/CourierMap';
import { Button } from '@material-ui/core';

const Completed = () => {
  const [isOpened, setIsOpened] = useState(false);
  const {
    delivery: { data: delivery },
  } = useSelector((state: Store) => state);
  const { hasCourierMap } = useCourierMap();

  return (
    <div className={cn(styles.Completed, hasCourierMap, hasCourierMap && styles.HasMap)}>
      <div className={cn(styles.Content)}>
        <div>
          <div className={cn(styles.BackGround)}></div>
          <Logo className={styles.Logo} />
          <div className={cn(styles.Title, hasCourierMap && styles.TitleMap)}>
            {delivery?.attributes.delivery.destination.first_name},
            {hasCourierMap ? ' представитель едет к вам' : ' давайте сверимся :)'}
          </div>
          <div className={styles.WrapperMapButton}>
            <Button className={styles.MapButton} onClick={() => setIsOpened(true)}>
              <Map size={50} /> <span style={{ marginLeft: '10px' }}> Показать агента на карте</span>
            </Button>
          </div>
          <InformationBlocks />
          <div className={cn(styles.Description, styles.Text, hasCourierMap && styles.DescriptionWithMap)}>
            Напомним о встрече за день и в день встречи. Она займёт всего 10 минут. Если изменятся планы, можно будет
            выбрать новые время и место
          </div>
          <div className={styles.EdditButton}>
            <OngoingButton />
          </div>
          <div className={styles.SubTitle}>Как пройдёт встреча</div>
          <div className={styles.InfoBar}>
            <PromptPanel />
          </div>
        </div>
      </div>
      {hasCourierMap && (
        <CourierMap
          agentLocation={{
            lat: delivery?.attributes.agent_location?.lat,
            lon: delivery?.attributes.agent_location?.lon,
          }}
          deliveryDestination={{
            lat: delivery?.attributes.delivery.destination.lat,
            lon: delivery?.attributes.delivery.destination.lon,
          }}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      )}
    </div>
  );
};

export default Completed;
