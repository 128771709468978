export enum DeliveryActions {
  GetDelivery = '[Pending] Get Delivery',
  GetDeliverySuccess = '[Success] Get Delivery',
  GetDeliveryError = '[Error] Get Delivery',

  PatchDelivery = '[Pending] Patch Delivery',
  PatchDeliverySuccess = '[Success] Patch Delivery',
  PatchDeliveryError = '[Error] Patch Delivery',

  SetStatusDelivery = '[Pending] Set Status Delivery',

  GetDeliveryCoordinationsMetroStations = '[Pending] Get Delivery Coordinations Metro Stations',
  GetDeliveryCoordinationsMetroStationsSuccess = '[Success] Get Delivery Coordinations Metro Stations',
  GetDeliveryCoordinationsMetroStationsError = '[Error] Get Delivery Coordinations Metro Stations',
}
