import Axios from 'axios-observable';
import { map } from 'rxjs/operators';

export interface IGetAddress {
  query: string;
  city: string;
  count?: number;
  locations?: any[];
}

export const getAddress = ({ query, city, locations, count = 10 }: IGetAddress) => {
  return Axios.request({
    method: 'POST',
    url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
    headers: {
      authorization: 'Token 41faea520dd0bce140e27867be38f958e0316d2a',
    },
    data: {
      query,
      count,
      locations: locations || [{ city }],
    },
  }).pipe(map(({ data: { suggestions: data } }) => ({ data })));
};
