import React from 'react';

import { createConsumer } from '@rails/actioncable';

type UseActionCableProps = Readonly<{
  channel: string;
  received?: (data: any) => void;
  token: string;
}>;

export const useActionCable = ({ channel, received, token }: UseActionCableProps) => {
  const cable = React.useMemo(() => {
    if (!process.env.REACT_APP_WEBSOCKET_ENDPOINT) return;

    const url = new URL(process.env.REACT_APP_WEBSOCKET_ENDPOINT);
    url.searchParams.set('delivery_token', token);

    return createConsumer(url.toString());

    // eslint-disable-next-line
  }, [token]);

  React.useEffect(() => {
    if (!cable || !channel) return;

    cable.subscriptions.create(
      { channel },
      {
        // Вызывается единожды при создании подписки.
        initialized: () => {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line
            console.log('WebSocket', channel, 'initialized');
          }
        },

        // Вызывается, когда подписка готова на сервере для использования.
        connected: () => {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line
            console.log('WebSocket', channel, 'connected');
          }
        },

        received,

        // Вызывается, когда закрывается соединения WebSocket.
        disconnected: () => {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line
            console.log('WebSocket', channel, 'disconnected');
          }
        },

        // Вызывается, когда подписка отвергается сервером.
        rejected() {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line
            console.log('WebSocket', channel, 'rejected');
          }
        },
      },
    );

    return () => cable.disconnect();
  }, [cable, channel, received]);
};
