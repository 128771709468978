import { Action } from 'redux-actions';
import { ActionsObservable, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MetroStationsActions } from './metroStations.actions';
import { getMetroStations, IGetMetroStations } from './metroStations.service';

export const getMetroStations$ = (actions$: ActionsObservable<Action<IGetMetroStations>>) => {
  return actions$.pipe(
    ofType(MetroStationsActions.GetMetroStations),
    switchMap(({ payload }) =>
      getMetroStations(payload).pipe(
        map((payload) => ({
          type: MetroStationsActions.GetMetroStationsSuccess,
          payload,
        })),
        catchError((err) =>
          of({
            type: MetroStationsActions.GetMetroStationsError,
            payload: err,
          }),
        ),
      ),
    ),
  );
};
