export const declOfNum = (n: number, text_forms: string[]): string => {
  const simplifiedN = Math.abs(n) % 100;

  const n1 = simplifiedN % 10;

  if (simplifiedN > 10 && simplifiedN < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 === 1) {
    return text_forms[0];
  }
  return text_forms[2];
};
