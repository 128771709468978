import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Store } from '../../../../../../../state';
import { Ban } from '../../../../../../sprites';

import styles from './TimeIntervals.module.scss';

interface ITimeIntervalsProps {
  setFieldValue: any;
  visible: boolean;
  values: any;
  disabled?: boolean;
}

const TimeIntervals: React.FC<ITimeIntervalsProps> = ({ setFieldValue, visible, values, disabled }) => {
  const {
    timeIntervals: { data: timeIntervals, isLoading },
    delivery: { data },
    zones: { data: zones },
  } = useSelector((state: Store) => state);

  const customerSchedulingKind = data?.attributes?.delivery?.partner?.customer_scheduling_kind;
  const precise = values.precise;

  const timeInterval = React.useMemo(() => {
    const slotsSelect = timeIntervals?.map(({ attributes: { name }, id }) => (
      <Button
        variant={id?.toString() === values.time_interval?.toString() ? 'contained' : 'outlined'}
        size="large"
        color="primary"
        key={id}
        onClick={() => setFieldValue('time_interval', id, false)}
        disabled={!values.date || new Date(values.date) < new Date()}
      >
        {name}
      </Button>
    ));

    const preciseSelect = (
      <FormControl>
        <InputLabel shrink id="precise-select-label">
          Точное время
        </InputLabel>
        <Select
          labelId="precise-simple-select-label"
          className={cn(styles.Select)}
          value={values.time_interval}
          defaultValue=""
          displayEmpty
          onChange={(e) => setFieldValue('time_interval', e.target.value, false)}
          disabled={!values.date || new Date(values.date) < new Date()}
        >
          {timeIntervals?.map(({ attributes: { name }, id }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

    const isPreciseZoneAvalible = zones[0]?.attributes.weight === 0;

    if (customerSchedulingKind === 'slots') {
      return slotsSelect;
    }

    if (customerSchedulingKind === 'both' && !isPreciseZoneAvalible) {
      return slotsSelect;
    }

    if (customerSchedulingKind === 'both' && isPreciseZoneAvalible && !precise) {
      return slotsSelect;
    }

    if (customerSchedulingKind === 'both' && isPreciseZoneAvalible && precise) {
      return preciseSelect;
    }

    if (customerSchedulingKind === 'precise' && !isPreciseZoneAvalible) {
      return slotsSelect;
    }
    if (customerSchedulingKind === 'precise' && isPreciseZoneAvalible) {
      return preciseSelect;
    }

    return slotsSelect;
  }, [customerSchedulingKind, precise, setFieldValue, timeIntervals, values.date, values.time_interval, zones]);

  if (visible || !values.date) return null;

  if (isLoading)
    return (
      <div className={styles.Block}>
        <LinearProgress />
      </div>
    );

  if (timeIntervals.length === 0)
    return (
      <div className={cn(styles.Block, styles.Error)}>
        <Ban size={20} className={styles.Icon} /> <span>К сожалению, в этот день доставка карты невозможна</span>
      </div>
    );

  return <div className={cn(styles.TimeIntervals)}>{timeInterval}</div>;
};

export default React.memo(TimeIntervals);
