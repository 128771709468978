import React, { memo, useMemo, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';

import CustomDatePicker from './components/CustomDatePicker';

import styles from './DateComponent.module.scss';

import { Store } from '../../../../../../../state';

interface DateComponentProps {
  setFieldValue: any;
  values: any;
  errors: any;
  disabled?: boolean;
}

const DateComponent = ({ setFieldValue, values, errors, disabled }: DateComponentProps) => {
  const {
    delivery: { data: delivery },
  } = useSelector((state: Store) => state);
  const nextDeliveryDate = delivery?.attributes?.delivery?.next_delivery_date;
  const holidays = delivery?.attributes?.holidays;

  const [selectedMonth, setSelectedMonth] = useState<1 | 2>(1);

  const curentMonth = useMemo(() => moment(nextDeliveryDate), [nextDeliveryDate]);
  const secondMonth = useMemo(() => moment(nextDeliveryDate).add(1, 'M'), [nextDeliveryDate]);
  const validAddres = useMemo(() => !!errors.addres || !values.addres, [errors.addres, values.addres]);

  return (
    <div className={cn(styles.DateComponent)}>
      <div className={cn(styles.Date)}>
        <div
          className={cn(
            styles.Title,
            { [styles.Shadow]: validAddres || selectedMonth !== 1 },
            { [styles.Pointer]: selectedMonth !== 1 },
          )}
          onClick={() => setSelectedMonth(1)}
        >
          {curentMonth.startOf('month').format('MMMM')}
        </div>
        <div
          className={cn(
            styles.Title,
            { [styles.Shadow]: validAddres || selectedMonth !== 2 },
            { [styles.Pointer]: selectedMonth !== 2 },
          )}
          onClick={() => setSelectedMonth(2)}
        >
          {secondMonth.startOf('month').format('MMMM')}
        </div>
      </div>

      <div className={cn(styles.Date)}>
        <div className={cn({ [styles.Hidden]: selectedMonth !== 1 })}>
          <CustomDatePicker
            month={curentMonth}
            setFieldValue={setFieldValue}
            value={values.date}
            disabled={validAddres || !!disabled}
            nextDeliveryDate={nextDeliveryDate}
            holidays={holidays}
            current={true}
          />
        </div>
        <div className={cn({ [styles.Hidden]: selectedMonth !== 2 })}>
          <CustomDatePicker
            month={secondMonth}
            setFieldValue={setFieldValue}
            value={values.date}
            disabled={validAddres || !!disabled}
            nextDeliveryDate={nextDeliveryDate}
            holidays={holidays}
            current={false}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(DateComponent);
