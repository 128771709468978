import cn from 'classnames';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Store } from '../../../state';
import { DeliveryActions } from '../../../state/delivery/delivery.actions';
import { MetroStationsActions } from '../../../state/metroStations/metroStations.actions';
import { TimeIntervalsActions } from '../../../state/timeIntervals/timeIntervals.actions';
import { ZonesActions } from '../../../state/zones/zones.actions';
import { Close } from '../../sprites';
import Logo from '../Logo';
import Form from './components/Form';
import Map from './components/Map';
import styles from './Ongoing.module.scss';

const Ongoing = () => {
  const dispatch = useDispatch();
  const { uuid }: { uuid: string } = useParams();

  const [isMap, setMap] = useState(false);
  const [isContains, setIsContains] = useState(true);
  const [coardinats, setCoardinats] = useState<[number, number] | undefined>();

  const {
    delivery: { data: delivery },
    timeIntervals: { data: timeIntervals },
    metroStations: { data: metroStations },
  } = useSelector((state: Store) => state);

  const initialValues = useMemo(
    () => ({
      date: delivery?.attributes?.delivery?.date || '',
      time_interval: delivery?.attributes?.delivery?.time_interval?.id || '',
      notes: delivery?.attributes?.delivery?.notes || '',
      addres: delivery?.attributes?.delivery?.destination.address || '',
      precise: delivery?.attributes?.delivery?.precise || false,
    }),
    [delivery],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        date: Yup.string().required('Это поле необходимо заполнить'),
        time_interval: Yup.number()
          .required('Это поле необходимо заполнить')
          .test(
            'not-delivery',
            '🙅‍♂️ К сожалению, в этот день доставка невозможна',
            () => timeIntervals && timeIntervals.length !== 0,
          ),
        notes: Yup.string().max(1000, 'Максимум 1000 символов'),
        addres: Yup.string().required('Это поле необходимо заполнить'),
      }),
    [timeIntervals],
  );

  const onSubmit = useCallback(
    ({ date, time_interval, addres, notes }) => {
      dispatch({
        type: DeliveryActions.PatchDelivery,
        payload: {
          uuid,
          date,
          notes,
          time_interval_id: +time_interval,
          address: addres,
          address_lat: coardinats?.[0],
          address_lon: coardinats?.[1],
        },
      });
    },
    [dispatch, uuid, coardinats],
  );

  const formikProps = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  React.useEffect(() => {
    formikProps.validateField('time_interval');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeIntervals]);

  const [addresError, serAddresError] = useState('');

  const errorLine = React.useMemo(() => {
    if (!coardinats && !formikProps.values.addres) return <div className={styles.Error}>Выберите адрес из списка</div>;
    if (!isContains) {
      if (!metroStations?.attributes?.name) return <div className={styles.Error}>Мы сюда пока не доставляем</div>;

      return (
        <div
          className={styles.Error}
          onClick={() => {
            formikProps.setFieldValue('addres', `Метро ${metroStations?.attributes?.name}`, false);
            formikProps.setFieldError('addres', undefined);
            serAddresError('');
            setCoardinats([metroStations?.attributes.lat, metroStations?.attributes.lon]);
          }}
        >
          Мы сюда пока не доставляем, предлагаем встретиться на{' '}
          <span className={styles.Link}>м. {metroStations?.attributes?.name}</span>
        </div>
      );
    }
    if (addresError) return <div className={styles.Error}>{addresError}</div>;
    if (formikProps.errors.addres) return <div className={styles.Error}>{formikProps.errors.addres}</div>;

    return null;
  }, [
    formikProps,
    coardinats,
    isContains,
    serAddresError,
    metroStations?.attributes?.name,
    metroStations?.attributes.lat,
    metroStations?.attributes.lon,
    addresError,
  ]);

  React.useEffect(() => {
    setCoardinats(
      delivery?.attributes.delivery.destination.lat && delivery?.attributes.delivery.destination.lon
        ? [delivery?.attributes.delivery.destination.lat, delivery?.attributes.delivery.destination.lon]
        : undefined,
    );
  }, [delivery?.attributes.delivery.destination.lat, delivery?.attributes.delivery.destination.lon]);

  React.useEffect(() => {
    if (!isContains && uuid && coardinats && delivery?.attributes?.delivery?.city?.code) {
      dispatch({
        type: MetroStationsActions.GetMetroStations,
        payload: {
          uuid,
          lat: coardinats[0],
          lon: coardinats[1],
          city_code: delivery?.attributes.delivery.city.code,
        },
      });
    }
  }, [isContains, uuid, coardinats, delivery?.attributes?.delivery?.city?.code, dispatch]);

  useEffect(() => {
    formikProps.validateField('addres');

    if (isContains) {
      formikProps.setFieldTouched('addres', false);
    }

    // eslint-disable-next-line
  }, [isContains, formikProps.values]);

  useEffect(() => {
    if (uuid && coardinats && formikProps?.values?.date) {
      const customerSchedulingKind = delivery?.attributes?.delivery?.partner?.customer_scheduling_kind;

      const payload: any = {
        uuid,
        lat: coardinats[0],
        lon: coardinats[1],
        date: formikProps?.values?.date,
      };

      if (['both'].includes(customerSchedulingKind!)) payload.precise = formikProps?.values?.precise;
      if (['precise'].includes(customerSchedulingKind!)) payload.precise = true;
      if (['slots'].includes(customerSchedulingKind!)) payload.precise = false;
      !(!formikProps.values.date || new Date(formikProps.values.date) < new Date()) &&
        dispatch({
          type: TimeIntervalsActions.GetTimeIntervals,
          payload,
        });
    }
  }, [
    uuid,
    coardinats,
    formikProps?.values?.date,
    dispatch,
    formikProps?.values?.precise,
    delivery?.attributes?.delivery?.partner?.customer_scheduling_kind,
  ]);

  useEffect(() => {
    if (coardinats) {
      dispatch({
        type: ZonesActions.GetZones,
        payload: { params: { filter: { lat: coardinats[0], lon: coardinats[1], kind: 'cost' } } },
      });
    }
  }, [formikProps.values.addres, coardinats, dispatch]);

  return (
    <div className={cn(styles.Ongoing)}>
      <div>
        <div className={cn(styles.Form)}>
          <Logo />
          <Form {...{ ...formikProps, isContains, setMap, errorLine, setCoardinats, serAddresError, addresError }} />
        </div>
      </div>
      <div className={cn({ [styles.LeftZero]: isMap })}>
        <div onClick={() => setMap(false)}>
          <Close size={46} className={cn(styles.Close, { [styles.LeftZero]: isMap })} />
        </div>
        <Map {...{ addres: formikProps.values.addres, setIsContains, coardinats }} />
      </div>
    </div>
  );
};

export default Ongoing;
