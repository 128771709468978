import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { Map as YandexMap, Placemark, Polygon } from 'react-yandex-maps';
import { Store } from '../../../../../state';
import styles from './Map.module.scss';

interface IMap {
  addres: string;
  setIsContains: React.Dispatch<React.SetStateAction<boolean>>;
  coardinats?: [number, number];
}

/**
 * Компонент рисует карту
 * @param {string} addres строка поиска адреса
 */
const Map = ({ setIsContains, coardinats }: IMap) => {
  const [polygonRef, setPolygonRef] = useState<any>(null);
  const [mapRef, setMapRef] = useState<any>(null);

  const {
    cityAreas: { data: cityAreas },
  } = useSelector((state: Store) => state);

  // Выставляем центер карты по дефолту это Москва
  const center = useMemo(() => [+(cityAreas?.attributes.lat || 55.76), +(cityAreas?.attributes.lon || 37.64)], [
    cityAreas,
  ]);

  // Выставляем полигоны
  const geometry = useMemo(
    () => cityAreas?.attributes?.border?.map((str) => JSON.parse(str.replace(/\(/g, '[').replace(/\)/g, ']'))),
    [cityAreas],
  );

  // включаем анимацию перелета карты не чаще чем каждые 300 мл
  useDebounce(
    () => {
      const isContains = polygonRef?.geometry?.contains(coardinats || []);

      setIsContains(isContains && !!coardinats);

      if (coardinats && mapRef && polygonRef) {
        mapRef
          .panTo([coardinats], {
            duration: 500,
          })
          .then(() => {
            const zoom = mapRef.getZoom();

            if (zoom !== 16 && isContains) {
              mapRef.setZoom(16, { duration: 500 });
            } else if (zoom !== 10 && !isContains) {
              mapRef.setZoom(10, { duration: 500 });
            }
          });
      } else if (mapRef) {
        const zoom = mapRef.getZoom();

        if (zoom !== 10) {
          mapRef.setZoom(10, { duration: 500 });
        }
        setTimeout(() => {
          mapRef.panTo([[cityAreas?.attributes.lat || 55.76, cityAreas?.attributes.lon || 37.64]], {
            duration: 500,
          });
        }, 700);
      }
    },
    300,
    [coardinats, mapRef, polygonRef],
  );

  // Выставляем маркеркер с анимацией
  const optionsMap = useMemo(() => {
    return {
      mark: coardinats ? (
        <Placemark
          geometry={coardinats}
          options={{
            iconLayout: 'default#image',
            iconImageHref: '/house-chimney-solid.svg',
            // Размеры метки.
            iconImageSize: [35, 35],
            iconImageOffset: [-17.5, -35],
          }}
        />
      ) : null,
    };

    // eslint-disable-next-line
  }, [JSON.stringify(coardinats)]);

  useEffect(() => {
    mapRef?.options?.set('maxAnimationZoomDifference', Infinity);
  }, [mapRef]);

  return (
    <div className={styles.Map}>
      <YandexMap
        state={{
          zoom: 10,
          center,
        }}
        width="100%"
        height="100%"
        instanceRef={setMapRef}
        options={{
          suppressMapOpenBlock: true,
          suppressObsoleteBrowserNotifier: true,
        }}
      >
        <Polygon
          geometry={geometry}
          options={{
            fillColor: '#B8E986',
            opacity: 0.2,
            strokeColor: '#B8E986',
            strokeOpacity: 0.2,
          }}
          instanceRef={setPolygonRef}
        />
        {optionsMap.mark}
      </YandexMap>
    </div>
  );
};

export default Map;
