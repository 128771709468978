import { Action } from 'redux-actions';
import { ActionsObservable, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DeliveryActions } from './delivery.actions';
import {
  getDelivery,
  getDeliveryCoordinationsMetroStations,
  IGetDelivery,
  IGetDeliveryCoordinationsMetroStations,
  IPatchDelivery,
  patchDelivery,
} from './delivery.service';

export const getDelivery$ = (actions$: ActionsObservable<Action<IGetDelivery>>) => {
  return actions$.pipe(
    ofType(DeliveryActions.GetDelivery),
    switchMap(({ payload }) =>
      getDelivery(payload).pipe(
        map((payload) => ({
          type: DeliveryActions.GetDeliverySuccess,
          payload,
        })),
        catchError((err) =>
          of({
            type: DeliveryActions.GetDeliveryError,
            payload: err,
          }),
        ),
      ),
    ),
  );
};

export const patchDelivery$ = (actions$: ActionsObservable<Action<IPatchDelivery>>) => {
  return actions$.pipe(
    ofType(DeliveryActions.PatchDelivery),
    switchMap(({ payload }) =>
      patchDelivery(payload).pipe(
        map((payload) => ({
          type: DeliveryActions.PatchDeliverySuccess,
          payload,
        })),
        catchError((err) =>
          of({
            type: DeliveryActions.PatchDeliveryError,
            payload: err,
          }),
        ),
      ),
    ),
  );
};

export const getDeliveryCoordinationsMetroStations$ = (
  actions$: ActionsObservable<Action<IGetDeliveryCoordinationsMetroStations>>,
) => {
  return actions$.pipe(
    ofType(DeliveryActions.GetDeliveryCoordinationsMetroStations),
    switchMap(({ payload }) =>
      getDeliveryCoordinationsMetroStations(payload).pipe(
        map((payload) => ({
          type: DeliveryActions.GetDeliveryCoordinationsMetroStationsSuccess,
          payload,
        })),
        catchError((err) =>
          of({
            type: DeliveryActions.GetDeliveryCoordinationsMetroStationsError,
            payload: err,
          }),
        ),
      ),
    ),
  );
};
