import { map } from 'rxjs/operators';
import Axios from 'axios-observable';
import { headers } from '../../shared/const/store';

export interface IGetCityAreas {
  uuid: string;
}

export const getCityAreas = ({ uuid }: IGetCityAreas) => {
  return Axios.request({
    method: 'GET',
    url: `${process.env.REACT_APP_URL}delivery_coordinations/${uuid}/city_areas`,
    headers,
  }).pipe(map(({ data: { data } }) => ({ data })));
};
