import Axios from 'axios-observable';
import { map } from 'rxjs/operators';
import { headers } from '../../shared/const/store';

export interface IGetDelivery {
  uuid: string;
}

export const getDelivery = ({ uuid }: IGetDelivery) => {
  return Axios.request({
    method: 'GET',
    url: `${process.env.REACT_APP_URL}delivery_coordinations/${uuid}`,
    headers,
  }).pipe(map(({ data: { data } }) => ({ data })));
};

export interface IPatchDelivery {
  uuid: string;
  date: string;
  address: string;
  time_interval_id: number;
  address_lat: number;
  address_lon: number;
  notes?: string;
}

export const patchDelivery = ({ uuid, ...attributes }: IPatchDelivery) => {
  return Axios.request({
    method: 'PATCH',
    url: `${process.env.REACT_APP_URL}delivery_coordinations/${uuid}`,
    headers,
    data: {
      data: {
        attributes,
      },
    },
  }).pipe(map(({ data: { data } }) => ({ data })));
};

export interface IGetDeliveryCoordinationsMetroStations {
  uuid: string;
  lat: number;
  lon: number;
  city_id: number;
}

export const getDeliveryCoordinationsMetroStations = ({
  uuid,
  lat,
  lon,
  city_id,
}: IGetDeliveryCoordinationsMetroStations) => {
  return Axios.request({
    method: 'GET',
    url: `${process.env.REACT_APP_URL}/delivery_coordinations/${uuid}/metro_stations/closest?lat=${lat}&lon=${lon}&city_id=${city_id}`,
    headers,
  }).pipe(map(({ data: { data } }) => ({ data })));
};
