import { handleActions, combineActions } from 'redux-actions';
import { DeliveryActions } from './delivery.actions';

interface Attributes {
  status: string;
  delivery: Delivery;
  holidays?: string[];
  agent_location?: {
    lat: number;
    lon: number;
    time: number;
  };
  agent_tracking?: boolean;
}

interface Delivery {
  status: string;
  date: string;
  time_interval: Timeinterval;
  notes: string;
  city: City;
  destination: Destination;
  partner: Partner;
  product: Product;
  precise: boolean;
  next_delivery_date?: string;
}

interface Product {
  name: string;
  required_documents: string[];
  short_name: string;
}

export enum CustomerSchedulingKindTypeEnum {
  slots = 'Слоты',
  precise = 'Точное время',
  both = 'Слоты или точное время',
}

export type CustomerSchedulingKindType = 'slots' | 'precise' | 'both';
interface Partner {
  customer_scheduling_kind?: CustomerSchedulingKindType;
  name: string;
  logo: null | string;
  site_url: null | string;
}

interface Destination {
  address: string;
  first_name: string;
  lat: number;
  lon: number;
  zone_weight?: null | number;
}

interface City {
  name: string;
  code: string;
  lat: number;
  lon: number;
  region?: string | null;
}

interface Timeinterval {
  id: number;
  from: number;
  to: number;
  code: string;
  name: string;
}

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface DeliveryState {
  data: Data | null;
  isLoading?: boolean;
  isError?: boolean;
}

const initialState: DeliveryState = {
  data: null,
};

const pendingActions = combineActions(DeliveryActions.GetDelivery, DeliveryActions.PatchDelivery);

const errorActions = combineActions(DeliveryActions.GetDeliveryError, DeliveryActions.PatchDeliveryError);

const successActions = combineActions(DeliveryActions.GetDeliverySuccess, DeliveryActions.PatchDeliverySuccess);

export default handleActions(
  {
    // -------------- PENDING --------------
    [pendingActions.toString()](state: DeliveryState) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
    // -------------- ERROR --------------
    [errorActions.toString()](state: DeliveryState) {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    // -------------- SUCCESS --------------
    [successActions.toString()](state: DeliveryState, { payload: { data } }) {
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
      };
    },
    [DeliveryActions.SetStatusDelivery](state: DeliveryState, { payload: { data } }) {
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: {
          ...state.data!,
          attributes: {
            ...state.data!.attributes,
            status: data!.attributes.status,
          },
        },
      };
    },
  },
  initialState,
);
