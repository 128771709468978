import { ActionsObservable, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Action } from 'redux-actions';
import { of } from 'rxjs';

import { CityAreasActions } from './cityAreas.actions';
import { getCityAreas, IGetCityAreas } from './cityAreas.service';

export const getCityAreas$ = (actions$: ActionsObservable<Action<IGetCityAreas>>) => {
  return actions$.pipe(
    ofType(CityAreasActions.GetCityAreas),
    switchMap(({ payload }) =>
      getCityAreas(payload).pipe(
        map((payload) => ({
          type: CityAreasActions.GetCityAreasSuccess,
          payload,
        })),
        catchError((err) =>
          of({
            type: CityAreasActions,
            payload: err,
          }),
        ),
      ),
    ),
  );
};
