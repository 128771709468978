import styles from './Logo.module.scss';
import React from 'react';
import cn from 'classnames';
import { Cross, FastRiver } from '../../sprites';
import { useSelector } from 'react-redux';
import { Store } from '../../../state';

interface ILogoProps {
  className?: string;
}

const Logo = ({ className }: ILogoProps) => {
  const {
    delivery: { data },
  } = useSelector((state: Store) => state);

  return (
    <div className={cn(styles.Logo, className)}>
      <a href="https://fastriver.ru/" target="_blank" rel="noreferrer" className={styles.FastRiver}>
        <FastRiver width={112} height={40} />
      </a>
      <Cross width={20} height={20} />
      <a
        href={data?.attributes?.delivery?.partner?.site_url || '/'}
        target="_blank"
        rel="noreferrer"
        className={styles.PartnerLogo}
      >
        <img
          src={data?.attributes?.delivery?.partner?.logo || ''}
          alt={data?.attributes?.delivery?.partner?.name}
          className={styles.Img}
        />
      </a>
    </div>
  );
};

export default Logo;
