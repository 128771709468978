import { handleActions, combineActions } from 'redux-actions';
import { ZonesActions } from './zones.actions';

type ZoneType = {
  attributes: {
    active: boolean;
    border: string;
    city_id: number;
    id: number;
    kind: string[];
    lat: number;
    lon: number;
    name: string;
    weight: number;
  };
  id: string;
  type: 'zone;';
};

export interface ZonesState {
  data: ZoneType[];
  isLoading?: boolean;
  isError?: boolean;
}

const initialState: ZonesState = {
  data: [],
};

const pendingActions = combineActions(ZonesActions.GetZones);

const errorActions = combineActions(ZonesActions.GetZonesError);

const successActions = combineActions(ZonesActions.GetZonesSuccess);

export default handleActions(
  {
    // -------------- PENDING --------------
    [pendingActions.toString()](state: ZonesState) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
    // -------------- ERROR --------------
    [errorActions.toString()](state: ZonesState) {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    // -------------- SUCCESS --------------
    [successActions.toString()](state: ZonesState, { payload: { data } }) {
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
      };
    },
  },
  initialState,
);
