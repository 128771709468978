import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import cn from 'classnames';
import moment from 'moment';

import { DeliveryActions } from '../../../../../state/delivery/delivery.actions';
import { Store } from '../../../../../state';

import styles from './OngoingButton.module.scss';

const OngoingButton = () => {
  const dispatch = useDispatch();

  const {
    delivery: { data: delivery },
  } = useSelector((state: Store) => state);

  const date = moment(delivery?.attributes.delivery.date);
  const leftDate = date.startOf('day').diff(moment().startOf('day'), 'days');

  const setStatusOngoing = useCallback(() => {
    dispatch({
      type: DeliveryActions.SetStatusDelivery,
      payload: {
        data: {
          attributes: {
            status: 'ongoing',
          },
        },
      },
    });
  }, [dispatch]);

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={setStatusOngoing}
      disabled={leftDate <= 0}
      className={cn(styles.OngoingButton)}
    >
      Изменить место и время
    </Button>
  );
};

export default OngoingButton;
