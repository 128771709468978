import { handleActions, combineActions } from 'redux-actions';
import { CityAreasActions } from './cityAreas.actions';

interface Attributes {
  border: string[];
  lat: number;
  lon: number;
  city_name: string;
  city: string;
}

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface CityAreasState {
  data: Data | null;
  isLoading?: boolean;
  isError?: boolean;
}

const initialState: CityAreasState = {
  data: null,
};

const pendingActions = combineActions(CityAreasActions.GetCityAreas);

const errorActions = combineActions(CityAreasActions.GetCityAreasError);

const successActions = combineActions(CityAreasActions.GetCityAreasSuccess);

export default handleActions(
  {
    // -------------- PENDING --------------
    [pendingActions.toString()](state: CityAreasState) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
    // -------------- ERROR --------------
    [errorActions.toString()](state: CityAreasState) {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    // -------------- SUCCESS --------------
    [successActions.toString()](state: CityAreasState, { payload: { data } }) {
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
      };
    },
  },
  initialState,
);
