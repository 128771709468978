import { combineActions, handleActions } from 'redux-actions';
import { MetroStationsActions } from './metroStations.actions';

interface Data {
  id: string;
  type: string;
  attributes: {
    id: number;
    full_name: null | string;
    name: string;
    metro_line_id: number;
    city_id: number;
    is_closed: boolean;
    lat: number;
    lon: number;
  };
}

export interface MetroStationsState {
  data: Data | null;
  isLoading?: boolean;
  isError?: boolean;
}

const initialState: MetroStationsState = {
  data: null,
};

const pendingActions = combineActions(MetroStationsActions.GetMetroStations);

const errorActions = combineActions(MetroStationsActions.GetMetroStationsError);

const successActions = combineActions(MetroStationsActions.GetMetroStationsSuccess);

export default handleActions(
  {
    // -------------- PENDING --------------
    [pendingActions.toString()](state: MetroStationsState) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
    // -------------- ERROR --------------
    [errorActions.toString()](state: MetroStationsState) {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    // -------------- SUCCESS --------------
    [successActions.toString()](state: MetroStationsState, { payload: { data } }) {
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
      };
    },
  },
  initialState,
);
