import React from 'react';
import cn from 'classnames';

import styles from './Precise.module.scss';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../../../state';

interface IPreciseProps {
  setFieldValue: any;
  visible: boolean;
  values: any;
  disabled?: boolean;
}

const Precise: React.FC<IPreciseProps> = ({ setFieldValue, visible, values, disabled }) => {
  const {
    delivery: { data },
    zones: { data: zones },
  } = useSelector((state: Store) => state);

  const customerSchedulingKind = data?.attributes?.delivery?.partner?.customer_scheduling_kind;

  if (visible || !values.date || !['both'].includes(customerSchedulingKind!) || !!zones[0]?.attributes.weight)
    return null;

  const handlerPrecise = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('time_interval', undefined, false);
    setFieldValue('precise', event.target.checked, false);
  };

  return (
    <div className={cn(styles.Precise)}>
      <FormControlLabel
        control={
          <Checkbox
            name="precise"
            color="primary"
            onChange={handlerPrecise}
            checked={values?.precise}
            disabled={!values.date || new Date(values.date) < new Date()}
          />
        }
        label="К точному времени"
        disabled={disabled}
      />
    </div>
  );
};

export default Precise;
