import React from 'react';
import cn from 'classnames';

import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <div className={cn(styles.NotFound)}>
      <div className={cn(styles.Body)}>
        <div className={cn(styles.ErrorName)}>404</div>
        <div className={cn(styles.Description)}>Страница не найдена</div>

        <div className={styles.WalkContainer}>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
