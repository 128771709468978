import { NotificationsActions } from './notifications.actions';
import { combineActions, handleActions } from 'redux-actions';

import { TimeIntervalsActions } from '../timeIntervals/timeIntervals.actions';
import { CityAreasActions } from '../cityAreas/cityAreas.actions';
import { DeliveryActions } from '../delivery/delivery.actions';
import { AddressActions } from '../address/address.actions';

export interface NotificationsState {
  variant?: 'success' | 'error' | 'warning' | 'info' | null;
  message?: string | null;
}

const initialState: NotificationsState = {};

export default handleActions(
  {
    [combineActions(
      NotificationsActions.SetNotifications,
      TimeIntervalsActions.GetTimeIntervalsError,
      CityAreasActions.GetCityAreasError,
      DeliveryActions.GetDeliveryError,
      DeliveryActions.PatchDeliveryError,
      AddressActions.GetAddressError,
    ).toString()](state: NotificationsState, { payload }: any) {
      return {
        ...state,
        variant: payload?.response?.data?.error ? 'error' : null,
        message: payload?.response?.data?.error || null,
      } as NotificationsState;
    },
  },
  initialState,
);
