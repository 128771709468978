import Axios from 'axios-observable';
import { map } from 'rxjs/operators';
import { headers } from '../../shared/const/store';

export interface IGetTimeIntervals {
  uuid: string;
  date: string;
  lat: number;
  lon: number;
  precise: boolean;
}

export const getTimeIntervals = ({ uuid, date, lat, lon, precise }: IGetTimeIntervals) => {
  return Axios.request({
    method: 'GET',
    headers,
    url: `${process.env.REACT_APP_URL}delivery_coordinations/${uuid}/time_intervals`,
    params: {
      date,
      lat,
      lon,
      precise,
    },
  }).pipe(map(({ data: { data } }) => ({ data })));
};
