import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Phone, Pasport, Document, Camera } from '../../../../sprites';
import { Store } from '../../../../../state';

import styles from './PromptPanel.module.scss';
import { useCourierMap } from '../../hooks';

const PromptPanel = () => {
  const { hasCourierMap } = useCourierMap();
  const required_documents = useSelector(
    (state: Store) => state.delivery.data?.attributes.delivery.product.required_documents,
  );
  const prompts = [
    {
      id: 1,
      img: <Phone size={40} className={styles.Phone} />,
      title: 'Позвоним за 30-40 минут',
      description: `Представитель свяжется с вами, чтобы подтвердить встречу`,
    },
    {
      id: 2,
      img: <Pasport width={37} height={44} className={styles.Pasport} />,
      title: 'Проверим паспорт',
      description: `Карту доставляем Вам лично в руки. Для получения карты, идентификация требуется по законодательству`,
    },
    {
      id: 3,
      img: <Document width={45} height={49} className={styles.Document} />,
      title: 'Подпишем документы',
      description: `Для проверки и активации карты`,
      items: required_documents,
    },
    {
      id: 4,
      img: <Camera size={40} className={styles.Camera} />,
      title: 'Сфотографируем документы и Вас с паспортом',
      description: `Фото направляется в Банк. Это безопасно и нужно для того, чтобы исключить передачу вашей карты третьим лицам`,
    },
  ];

  const list = useCallback(
    (stings: string[]) => (
      <div className={styles.List}>
        <div className={cn(styles.Description, styles.Title)}>Вам потребуется</div>
        {stings.map((string) => (
          <div className={styles.Description} key={string}>
            - {string}
          </div>
        ))}
      </div>
    ),
    [],
  );

  return (
    <div className={cn(styles.PromptPanel)}>
      {prompts.map(({ id, title, description, img, items }) => (
        <div key={id} className={cn(styles.Prompt, hasCourierMap && styles.PromptWidthMap)}>
          <div className={styles.Img}>{img}</div>
          <div className={styles.Block}>
            <div className={styles.Title}>{title}</div>
            <div className={styles.Description}>{description}</div>
            <div className={styles.List}></div>
            {!!items?.length && list(items)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(PromptPanel);
