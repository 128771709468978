import React, { useMemo } from 'react';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';

import { Store } from '../../../../../state';
import { declOfNum } from '../../../../scripts';

import styles from './InformationBlocks.module.scss';
import { Button } from '@material-ui/core';
import { useCourierMap } from '../../hooks';

function makeIcsFile(start: string, end: string, summary: string, description: string) {
  const event = `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
BEGIN:VTIMEZONE
TZID:Europe/Moscow
TZURL:http://tzurl.org/zoneinfo-outlook/Europe/Moscow
X-LIC-LOCATION:Europe/Moscow
BEGIN:STANDARD
TZOFFSETFROM:+0300
TZOFFSETTO:+0300
TZNAME:MSK
DTSTART:19700101T000000
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
DTSTAMP:${moment().format('YYYYMMDDTHHmmSS')}
UID:${moment().format('YYYYMMDDTHHmmSS')}-107241042@marudot.com
DTSTART;TZID=Europe/Moscow:${start}
DTEND;TZID=Europe/Moscow:${end}
SUMMARY:${summary}
URL:${window.location.href}
DESCRIPTION:${description}
LOCATION:Москва
END:VEVENT
END:VCALENDAR`;

  const file = new File([event], 'event.ics', { type: 'text/plain' });

  return window.URL.createObjectURL(file);
}

function addToCalendar(start: string, end: string, summary: string, description: string) {
  const link = document.createElement('a');

  link.href = makeIcsFile(start, end, summary, description);

  link.setAttribute('download', `delivery${moment().valueOf()}.ics`);

  document.body.appendChild(link);

  link.click();

  link!.parentNode!.removeChild(link);
}

const InformationBlocks = () => {
  const {
    delivery: { data: delivery },
  } = useSelector((state: Store) => state);
  const { hasCourierMap } = useCourierMap();

  const date = moment(delivery?.attributes.delivery.date);
  const leftDate = date.startOf('day').diff(moment().startOf('day'), 'days');
  const dayForms = useMemo(() => declOfNum(leftDate, ['день', 'дня', 'дней']), [leftDate]);

  const timeInterval = delivery?.attributes.delivery.time_interval.name.split('-');

  return (
    <div className={cn(styles.InformationBlocks)}>
      <div className={cn(styles.Block, hasCourierMap && styles.BlockWithMap)}>
        <div className={styles.SubTitle}>Привезём</div>
        <div className={styles.Text}>
          {delivery?.attributes.delivery.product.short_name} от {delivery?.attributes.delivery.partner.name}
        </div>
        <div className={styles.Text}>{delivery?.attributes.delivery.destination.address}</div>
      </div>
      <div className={cn(styles.Block, hasCourierMap && styles.BlockWithMap)}>
        <div className={styles.SubTitle}>
          {leftDate > 0 ? `Через ${leftDate} ${dayForms}` : leftDate === 0 ? 'Доставим сегодня' : 'Доставлено'}
        </div>
        <div className={styles.Text}>
          {date.format('D MMMM')} с {timeInterval?.[0]} до {timeInterval?.[1]}
        </div>

        {!hasCourierMap && (
          <div className={styles.AddToCalendar}>
            <Button
              endIcon={<EventNoteIcon />}
              onClick={() =>
                addToCalendar(
                  moment(date)
                    .add(timeInterval?.[0].slice(0, 2), 'hours')
                    .add(timeInterval?.[0].slice(3, 5), 'months')
                    .format('YYYYMMDDTHHmmSS'),
                  moment(date)
                    .add(timeInterval?.[1].slice(0, 2), 'hours')
                    .add(timeInterval?.[0].slice(3, 5), 'months')
                    .format('YYYYMMDDTHHmmSS'),
                  `Привезём: ${delivery?.attributes.delivery.product.short_name} от ${delivery?.attributes.delivery.partner.name}`,
                  ``,
                )
              }
            >
              Добавить в календарь
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InformationBlocks;
