import { useSelector } from 'react-redux';
import { Store } from '../../../state';

export const useCourierMap = () => {
  const {
    delivery: { data: delivery },
  } = useSelector((state: Store) => state);
  const isCompleted = 'completed' === delivery?.attributes.status;
  const isDeliveryScheduled = 'scheduled' === delivery?.attributes.delivery.status;
  const isDeliveryActive = Boolean(delivery?.attributes.agent_location);
  const isTracking = delivery?.attributes.agent_tracking !== false;

  return {
    hasCourierMap: isCompleted && isDeliveryScheduled && isDeliveryActive && isTracking,
  };
};
