import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Placemark, Map as YandexMap } from 'react-yandex-maps';
import { useActionCable } from '../../../../hooks/useActionCable';
import { useParams } from 'react-router-dom';
import { Map, Close } from '../../../../sprites';
import Button from '@material-ui/core/Button';
import styles from './Map.module.scss';
import cn from 'classnames';

type CourierMapProps = Readonly<{
  agentLocation: {
    lat: number | undefined;
    lon: number | undefined;
  };
  deliveryDestination: {
    lat: number | undefined;
    lon: number | undefined;
  };
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
}>;

// Выставляем по дефолту  Москву
const defaultLat = 55.76;
const defaultLon = 37.64;

export const CourierMap = ({
  agentLocation: { lat: agentLocationLat = defaultLat, lon: agentLocationLon = defaultLon },
  deliveryDestination: { lat: deliveryDestinationLat = defaultLat, lon: deliveryDestinationLon = defaultLon },
  isOpened,
  setIsOpened,
}: CourierMapProps) => {
  const [geometry, setGeometry] = useState<[number, number]>([agentLocationLat, agentLocationLon]);

  const mapRef = useRef<any>(null);
  const maps = useRef<any>(null);
  const [isLoad, setLoad] = useState(false);

  const { uuid }: { uuid: string } = useParams();

  const received = useCallback((data: any) => {
    const lat = data.lat;
    const lon = data.lon;
    setGeometry([lat, lon]);
  }, []);

  useActionCable({
    channel: `Api::External::DeliveryLocationChannel`,
    received: received,
    token: uuid,
  });

  const [state, setState] = useState({
    zoom: 10,
    center: useMemo(() => [deliveryDestinationLat, deliveryDestinationLon], [
      deliveryDestinationLat,
      deliveryDestinationLon,
    ]),
  });

  useEffect(() => {
    if (maps.current && isLoad) {
      const zoom = maps.current.util.bounds.getCenterAndZoom(
        [
          [agentLocationLat, agentLocationLon],
          [deliveryDestinationLat, deliveryDestinationLon],
        ],
        mapRef.current.container.getSize(),
      );
      setState(zoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoad]);

  return (
    <div className={cn(styles.Root, isOpened && styles.IsOpenedMap)}>
      <YandexMap
        state={state}
        className={cn(styles.Map)}
        width="100%"
        height="100%"
        instanceRef={(ref) => (mapRef.current = ref)}
        options={{
          suppressMapOpenBlock: true,
          suppressObsoleteBrowserNotifier: true,
        }}
        onLoad={(ref) => {
          setLoad(true);
          maps.current = ref;
        }}
      >
        <Placemark
          // eslint-disable-next-line react-hooks/exhaustive-deps
          geometry={useMemo(() => [geometry[0], geometry[1]], [geometry[0], geometry[1]])}
          options={{
            iconLayout: 'default#image',
            iconImageHref: '/person-carry-box.svg',
            iconImageSize: [35, 35],
            iconImageOffset: [-17.5, -35],
          }}
        />
        <Placemark
          geometry={useMemo(() => [deliveryDestinationLat, deliveryDestinationLon], [
            deliveryDestinationLat,
            deliveryDestinationLon,
          ])}
          options={{
            iconLayout: 'default#image',
            iconImageHref: '/house-chimney-solid.svg',
            // Размеры метки.
            iconImageSize: [25, 28],
            iconImageOffset: [-12.5, -28],
          }}
        />
      </YandexMap>
      {isOpened && (
        <Button onClick={() => setIsOpened(false)} className={cn(styles.CloseButton)}>
          <Close size={50} />
        </Button>
      )}
    </div>
  );
};
