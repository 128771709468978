import Axios from 'axios-observable';
import { map } from 'rxjs/operators';
import { headers } from '../../shared/const/store';

export interface IGetMetroStations {
  uuid: string;
  lat: number;
  lon: number;
  city_code: string;
}

export const getMetroStations = ({ uuid, lat, lon, city_code }: IGetMetroStations) => {
  return Axios.request({
    method: 'GET',
    url: `${process.env.REACT_APP_URL}/delivery_coordinations/${uuid}/metro_stations/closest?lat=${lat}&lon=${lon}&city_code=${city_code}`,
    headers,
  }).pipe(map(({ data: { data } }) => ({ data })));
};
