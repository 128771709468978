import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import cn from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../state';
import { AddressActions } from '../../../state/address/address.actions';
import styles from './AutoCompleteAddress.module.scss';

type AutoCompleteAddressProps = Readonly<{
  value?: string;
  onChange?: (v: string) => void;
  onBlur?: any;
  onError?: (v: string) => void;
  setCoardinats?: (arg?: [number, number]) => void;
}>;

const AutoCompleteAddress: React.FC<AutoCompleteAddressProps> = ({
  value,
  onChange,
  onBlur,
  onError,
  setCoardinats,
}) => {
  const dispatch = useDispatch();

  const {
    delivery: { data: delivery },
    address: { data: address },
  } = useSelector((state: Store) => state);

  const findAddres = React.useCallback(
    ({ payload: { query, city, region } }: any) => {
      const locations: { region?: string; city?: string }[] = [];

      region && locations.push({ region });
      if (city) {
        locations.push({ region: city }, { city });
      }
      if (city.toLowerCase() === 'Москва и область'.toLowerCase()) {
        locations.push({ city: 'Москва' }, { region: 'Москва' }, { region: 'Московская' });
      }

      dispatch({
        type: AddressActions.GetAddress,
        payload: { query: query ? query : 'а', locations },
      });
    },
    [dispatch],
  );

  const setError = React.useCallback(
    ({ options }: any) => {
      const { street, house, geo_lat, geo_lon, settlement_type_full, street_type, settlement } = options?.data || {};
      const isUnderground = street_type === 'м';

      if (!street && (!settlement_type_full || !settlement) && !isUnderground && value) {
        onError?.('Укажите улицу');
      } else if (!house && !isUnderground && value) {
        onError?.('Укажите дом');
      } else if ((!geo_lat || !geo_lon) && value) {
        onError?.('Нет координат');
      } else {
        onError?.('');
      }
    },
    [onError, value],
  );

  React.useEffect(() => {
    const options = address?.find((item) => item.value === value);

    if (options) {
      setError({ options });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  React.useEffect(() => {
    findAddres({
      payload: {
        query: delivery?.attributes.delivery.destination.address,
        city: delivery?.attributes.delivery.city.name,
      },
    });
  }, [delivery?.attributes.delivery.destination.address, delivery?.attributes.delivery.city.name, findAddres]);

  return (
    <Autocomplete
      options={address || []}
      inputValue={value}
      getOptionSelected={(options) => {
        return !!options?.data;
      }}
      onChange={(event, options: any) => {
        setError({ options });
        onChange?.(options?.value);
        setCoardinats?.(
          options?.data.geo_lat || options?.data.geo_lon ? [options.data.geo_lat, options.data.geo_lon] : undefined,
        );
      }}
      filterOptions={(str) => str}
      getOptionLabel={(addres) => addres?.value}
      renderInput={(params: any) => (
        <TextField
          {...params}
          onChange={(e: any) => {
            onChange?.(e.target.value);
            onError?.('Выберите адрес из списка');
            setCoardinats?.(undefined);

            findAddres({
              payload: {
                query: e.target.value.trim(),
                region: delivery?.attributes.delivery.city.region?.toLowerCase(),
                city: delivery?.attributes.delivery.city.name?.toLowerCase(),
              },
            });
          }}
          className={cn(styles.Input, styles.Address)}
          placeholder="Улица Палиха, 10с5"
          onBlur={onBlur}
          id="addres"
          name="addres"
        />
      )}
    />
  );
};

export default AutoCompleteAddress;
