import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { getAddress$ } from './address/address.effect';
import address, { AddressState } from './address/address.reducer';
import { getCityAreas$ } from './cityAreas/cityAreas.effect';
import cityAreas, { CityAreasState } from './cityAreas/cityAreas.reducer';
import { getDelivery$, patchDelivery$ } from './delivery/delivery.effect';
import delivery, { DeliveryState } from './delivery/delivery.reducer';
import { getMetroStations$ } from './metroStations/metroStations.effect';
import metroStations, { MetroStationsState } from './metroStations/metroStations.reducer';
import notifications, { NotificationsState } from './notifications/notifications.reducer';
import { getTimeIntervals$ } from './timeIntervals/timeIntervals.effect';
import timeIntervals, { TimeIntervalsState } from './timeIntervals/timeIntervals.reducer';
import { getZones$ } from './zones/zones.effect';
import zones, { ZonesState } from './zones/zones.reducer';

export interface Store {
  zones: ZonesState;
  timeIntervals: TimeIntervalsState;
  cityAreas: CityAreasState;
  delivery: DeliveryState;
  address: AddressState;
  notifications: NotificationsState;
  metroStations: MetroStationsState;
}

const rootReducer = combineReducers({
  timeIntervals,
  notifications,
  metroStations,
  cityAreas,
  delivery,
  address,
  zones,
});

const epicMiddleware = createEpicMiddleware();

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

epicMiddleware.run(
  combineEpics(
    getDelivery$,
    getTimeIntervals$,
    getCityAreas$,
    getAddress$,
    patchDelivery$,
    getMetroStations$,
    getZones$,
  ),
);

export default store;
