import { handleActions, combineActions } from 'redux-actions';
import { TimeIntervalsActions } from './timeIntervals.actions';

interface Datum {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  id: number;
  from: number;
  to: number;
  code: string;
  name: string;
}

export interface TimeIntervalsState {
  data: Datum[];
  isLoading?: boolean;
  isError?: boolean;
}

const initialState: TimeIntervalsState = {
  data: [],
};

const pendingActions = combineActions(TimeIntervalsActions.GetTimeIntervals);

const errorActions = combineActions(TimeIntervalsActions.GetTimeIntervalsError);

const successActions = combineActions(TimeIntervalsActions.GetTimeIntervalsSuccess);

export default handleActions(
  {
    // -------------- PENDING --------------
    [pendingActions.toString()](state: TimeIntervalsState) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
    // -------------- ERROR --------------
    [errorActions.toString()](state: TimeIntervalsState) {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    // -------------- SUCCESS --------------
    [successActions.toString()](state: TimeIntervalsState, { payload: { data } }) {
      return {
        ...state,
        isLoading: false,
        isError: false,
        data,
      };
    },
  },
  initialState,
);
